<template>
    <Form
            @submit="resetPassword(data.inputFormData)"
            :validation-schema="schema"
            v-slot="{ errors }"
    >
        <div class="form-group mb-3">
            <label class="label">Password</label>
            <Field
                    type="password"
                    class="form-control"
                    v-model="data.inputFormData.password"
                    placeholder="Password"
                    name="password"
            />
            <span class="text-danger">{{ errors.password }}</span>
        </div>
        <div class="form-group mb-3">
            <label class="label">Confirm Password</label>
            <Field
                    type="password"
                    class="form-control"
                    v-model="data.inputFormData.c_password"
                    placeholder="Confirm Password"
                    name="c_password"
            />
            <span class="text-danger">{{ errors.c_password }}</span>
        </div>
        <div class="form-group mb-2">
            <button
                    type="submit"
                    class="form-control btn btn-primary rounded submit px-3"
            >
                Reset Password
            </button>
        </div>
    </Form>
</template>

<script>
    import {Form, Field} from "vee-validate";
    import * as Yup from "yup";
    import HelperFunction from "@/common/helpers";
    import axios from "axios";
    import Cookies from "js-cookie";
    import {useRoute} from "vue-router";

    export default {
        name: "set-new-password-form",
        components: {
            Form,
            Field,
        },
        setup() {
            const route = useRoute();
            const {data, showToast} = HelperFunction();

            try {
                const schema = Yup.object().shape({
                    password: Yup.string()
                        .min(6, "Password must be at least 6 characters")
                        .required("Password is required"),
                    c_password: Yup.string().required("Confirm Password is required"), //Need to Add Match Check
                });

                //OnCLick
                async function resetPassword(formData) {
                    try {
                        const resetPasswordEndpoint =
                            process.env.VUE_APP_BACKEND_URL +
                            vueConfig.Auth.ResetPasswordEndPoint;

                        const resetToken = route.params.token;
                        const resetEmail = Cookies.get("reset_email");
                        formData = {...formData, email: resetEmail, token: resetToken};

                        //API CALL
                        const response = await axios.post(resetPasswordEndpoint, formData);

                        //REMOVE Cookie for Reset Email
                        if (response.status === 200) {
                            Cookies.remove("reset_email");
                        }

                        showToast(response);
                    } catch (err) {
                        showToast(err);
                    }
                }

                return {
                    schema,
                    resetPassword,
                    data,
                };
            } catch (err) {
            }
        },
    };
</script>


   