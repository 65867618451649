<template>
    <div class="site-login w-100 p-xxl-5 p-xl-5 p-lg-3 p-md-2 p-sm-2 p-2">
        <h2 class="text-purple mb-3">Reset Password</h2>
        <set-new-password-form></set-new-password-form>
    </div>
</template>

<script>
    import SetNewPasswordForm from "./SetNewPasswordForm.vue";

    export default {
        name: "new-password",
        components: {
            SetNewPasswordForm,
        },
    };
</script>
